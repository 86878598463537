import React, { useState } from 'react';
import { DateRangePicker } from '@amzn/awsui-components-react';
import { DateRangePickerProps } from '@amzn/awsui-components-react/polaris/date-range-picker/interfaces';
import { DateTime } from 'luxon';

export type DateTimeRange = {
    startDate: Date | null,
    endDate: Date | null
};

type Props = {
    setDateTimeRange: (range: DateTimeRange) => void
};

const TableDateRangePicker : React.FC<Props> = ({ setDateTimeRange }) => {
    const [value, setValue] = useState<null | DateRangePickerProps.Value>(null);

    return (
        <DateRangePicker
            onChange={({ detail }) => {
                setDateTimeRange(getDates(detail.value));
                setValue(detail.value);
            }}
            value={value}
            relativeOptions={[
                {
                    key: 'previous-5-minutes',
                    amount: 5,
                    unit: 'minute',
                    type: 'relative'
                },
                {
                    key: 'previous-30-minutes',
                    amount: 30,
                    unit: 'minute',
                    type: 'relative'
                },
                {
                    key: 'previous-1-hour',
                    amount: 1,
                    unit: 'hour',
                    type: 'relative'
                },
                {
                    key: 'previous-3-hours',
                    amount: 3,
                    unit: 'hour',
                    type: 'relative'
                },
                {
                    key: 'previous-6-hours',
                    amount: 6,
                    unit: 'hour',
                    type: 'relative'
                },
                {
                    key: 'previous-1-day',
                    amount: 1,
                    unit: 'day',
                    type: 'relative'
                },
                {
                    key: 'previous-2-days',
                    amount: 2,
                    unit: 'day',
                    type: 'relative'
                },
                {
                    key: 'previous-3-days',
                    amount: 3,
                    unit: 'day',
                    type: 'relative'
                },
            ]}
            isValidRange={range => {
                if (range !== null && range.type === 'absolute') {
                    const [ startDateWithoutTime ] = range.startDate.split('T');
                    const [ endDateWithoutTime ] = range.endDate.split('T');
                    if (!startDateWithoutTime || !endDateWithoutTime) {
                        return {
                            valid: false,
                            errorMessage: 'The selected date range is incomplete. Select a start and end date for the date range.'
                        };
                    }
                    if (new Date(range.startDate).getTime() - new Date(range.endDate).getTime() > 0) {
                        return {
                            valid: false,
                            errorMessage: 'The selected date range is invalid. The start date must be before the end date.'
                        };
                    }
                }
                return { valid: true };
            }}
            customRelativeRangeUnits={['second', 'minute', 'hour', 'day']}
            placeholder='Filter by a date and time range'
        />
    );
};

export default TableDateRangePicker;

function getDates(value: null | DateRangePickerProps.Value): DateTimeRange {
    let startDate: Date | null = null;
    let endDate: Date | null = null;
    if (value?.type === 'absolute') {
        startDate = new Date(value.startDate);
        endDate = new Date(value.endDate);
    } else if (value?.type === 'relative') {
        endDate = new Date();
        startDate = DateTime.now().minus({ [`${value.unit}s`]: value.amount }).toJSDate();
    }
    return { startDate, endDate };
}