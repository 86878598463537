import { getClient } from "./client";
import {
    AddBrokerTagCommand,
    AddBrokerTagCommandOutput,
    BrokerEngineType,
    DescribeRabbitQueueCommand,
    DescribeRabbitQueueCommandOutput,
    GetBrokerCommand,
    GetBrokerCommandOutput,
    GetBrokerEventsCommand,
    GetBrokerEventsCommandOutput,
    GetBrokerInstancesCommand,
    GetBrokerInstancesCommandOutput,
    DescribeInstanceImageCommand,
    DescribeInstanceImageOutput,
    GetBrokerTagsCommand,
    GetBrokerTagsCommandOutput,
    GetBrokerWorkflowsCommand,
    GetBrokerWorkflowsCommandOutput,
    GetCloudwatchDashboardLinkCommand,
    GetCloudwatchDashboardLinkCommandOutput,
    GetCodeDeploymentsCommand,
    GetCodeDeploymentsCommandOutput,
    DescribeBrokerAutoScalingGroupsCommand,
    DescribeBrokerAutoScalingGroupsOutput,
    GetCustomerMetricNamesCommand,
    GetCustomerMetricNamesCommandOutput,
    GetFailedWorkflowsCommand,
    GetFailoverHistoryCommand,
    GetFailoverHistoryCommandOutput,
    GetHOTWExecutionsCommand,
    GetHOTWExecutionsCommandOutput,
    GetLogStreamsCommand,
    GetLogStreamsCommandOutput,
    GetRabbitBrokerEnvironmentCommand,
    GetRabbitBrokerEnvironmentCommandOutput,
    GetRabbitBrokerMemoryBreakdownCommand,
    GetRabbitBrokerMemoryBreakdownCommandOutput,
    GetRabbitBrokerPoliciesCommand,
    GetRabbitBrokerPoliciesCommandOutput,
    GetRabbitBrokerQueuesCommand,
    GetRabbitBrokerQueuesCommandOutput,
    GetRabbitBrokerTimelineCommand, GetRabbitBrokerTimelineCommandOutput,
    GetRabbitBrokerUnresponsiveQueuesCommand,
    GetRabbitBrokerUnresponsiveQueuesCommandOutput,
    GetRabbitClusterStatusCommand,
    GetRabbitClusterStatusCommandOutput,
    GetReachoutTemplatesCommand,
    GetReachoutTemplatesCommandOutput,
    GetSimIssuesCommand,
    GetSimIssuesCommandOutput,
    GetSimMarkdownCommand,
    GetSimMarkdownCommandOutput,
    GetStuckWorkflowsCommand,
    GetStuckWorkflowsCommandOutput,
    GetWorkflowsOutput,
    RemoveBrokerTagCommand,
    RemoveBrokerTagCommandOutput,
    GetRabbitBrokerVhostsOutput,
    GetRabbitBrokerVhostsCommand,
    GetLogEventsCommand,
    GetLogEventsCommandOutput,
    GetQuorumStatusCommand,
    GetQuorumStatusCommandOutput,
    GetBrokerEngineVersionsCommandOutput,
    GetBrokerEngineVersionsCommand,
    UpdateBrokerStateCommand,
    UpdateBrokerStateCommandOutput
} from "@amzn/amazonmq-opsconsole-client";

import {
    GetBrokerTimelineEventCollectionRequest,
} from "@amzn/amazonmq-opsconsole-client/dist-types/models/models_0";

export async function getBrokerById(brokerId: string): Promise<GetBrokerCommandOutput> {
    const client = await getClient();
    const result = client.send(new GetBrokerCommand({brokerId: brokerId}));
    return result;
}

export async function getBrokerInstances(brokerId: string, snapshotId: string): Promise<GetBrokerInstancesCommandOutput> {
    const client = await getClient();
    const result = client.send(new GetBrokerInstancesCommand({brokerId: brokerId, snapshotId: snapshotId}));
    return result;
}

export async function getLogStreams(brokerId: string, snapshotId: string):  Promise<GetLogStreamsCommandOutput> {
    const client = await getClient();
    const result = client.send(new GetLogStreamsCommand({brokerId: brokerId, snapshotId: snapshotId}));
    return result;
}

export async function getLogEvents(brokerId: string, logGroupName: string, logStreamName: string, timestampFrom: Date, timestampTo?: Date):  Promise<GetLogEventsCommandOutput> {
    const client = await getClient();
    const result = client.send(new GetLogEventsCommand({
        brokerId: brokerId,
        logGroupName: logGroupName,
        logStreamName: logStreamName,
        timestampFrom: timestampFrom,
        timestampTo: timestampTo
    }));
    return result;
}

export function getLogs(brokerId: string, snapshotId: string, logStreamId: string): Promise<any> {
    // TODO not implemented yet
    return Promise.reject();
}

export async function getSimIssues(brokerId: string, snapshotId: string): Promise<GetSimIssuesCommandOutput> {
    const client = await getClient();
    const result = client.send(new GetSimIssuesCommand({brokerId: brokerId, snapshotId: snapshotId}));
    return result;
}

export async function getBrokerWorkflows(brokerId: string): Promise<GetBrokerWorkflowsCommandOutput> {
    const client = await getClient();
    const result = client.send(new GetBrokerWorkflowsCommand({brokerId: brokerId}));
    return result;
}

export async function getFailedWorkflows(): Promise<GetWorkflowsOutput> {
    const client = await getClient();
    const result = client.send(new GetFailedWorkflowsCommand());
    return result;
}

export async function getStuckWorkflows(): Promise<GetStuckWorkflowsCommandOutput> {
    const client = await getClient();
    const result = client.send(new GetStuckWorkflowsCommand());
    return result;
}

export async function getReachoutTemplates(engineType: BrokerEngineType): Promise<GetReachoutTemplatesCommandOutput> {
    const client = await getClient();
    const result = client.send(new GetReachoutTemplatesCommand({engineType: engineType}));
    return result;
}

export async function getCustomerMetricNames(brokerId: string): Promise<GetCustomerMetricNamesCommandOutput> {
    const client = await getClient();
    const result = client.send(new GetCustomerMetricNamesCommand({brokerId: brokerId}));
    return result;
}

export async function getCloudwatchDashboardLink(brokerId: string, snapshotId: string): Promise<GetCloudwatchDashboardLinkCommandOutput> {
    const client = await getClient();
    const result = client.send(new GetCloudwatchDashboardLinkCommand({brokerId: brokerId, snapshotId: snapshotId}));
    return result;
}

export async function getEvents(brokerId: string): Promise<GetBrokerEventsCommandOutput> {
    const client = await getClient();
    const result = client.send(new GetBrokerEventsCommand({brokerId: brokerId}));
    return result;
}

export async function getSimMarkdown(brokerId: string, snapshotId: string): Promise<GetSimMarkdownCommandOutput> {
    const client = await getClient();
    const result = client.send(new GetSimMarkdownCommand({brokerId: brokerId, snapshotId: snapshotId}));
    return result;
}

export async function getHOTWExecutions(brokerId: string): Promise<GetHOTWExecutionsCommandOutput> {
    const client = await getClient();
    const result = client.send(new GetHOTWExecutionsCommand({brokerId: brokerId}));
    return result;
}

export async function getCodeDeployments(brokerId: string): Promise<GetCodeDeploymentsCommandOutput> {
    const client = await getClient();
    const result = client.send(new GetCodeDeploymentsCommand({brokerId: brokerId}));
    return result;
}

export async function getInstanceImage(brokerId: string, amiId: string): Promise<DescribeInstanceImageOutput> {
    const client = await getClient();
    const result = client.send(new DescribeInstanceImageCommand({brokerId: brokerId, amiId: amiId}));
    return result;
}

export async function getBrokerAutoScalingGroup(brokerId: string): Promise<DescribeBrokerAutoScalingGroupsOutput> {
    const client = await getClient();
    const result = client.send(new DescribeBrokerAutoScalingGroupsCommand({brokerId: brokerId}));
    return result;
}

export async function getFailoverHistory(brokerId: string, snapshotId: string): Promise<GetFailoverHistoryCommandOutput> {
    const client = await getClient();
    const result = client.send(new GetFailoverHistoryCommand({brokerId: brokerId, snapshotId: snapshotId}));
    return result;
}

export async function getRabbitBrokerMemoryBreakdown(brokerId: string): Promise<GetRabbitBrokerMemoryBreakdownCommandOutput> {
    const client = await getClient();
    const result = client.send(new GetRabbitBrokerMemoryBreakdownCommand({brokerId: brokerId}));
    return result;
}

export async function getRabbitBrokerEnvironment(brokerId: string): Promise<GetRabbitBrokerEnvironmentCommandOutput> {
    const client = await getClient();
    const result = client.send(new GetRabbitBrokerEnvironmentCommand({brokerId: brokerId}));
    return result;
}

export async function getRabbitClusterStatus(brokerId: string, instanceId: string | undefined, formatter: string): Promise<GetRabbitClusterStatusCommandOutput> {
    const client = await getClient();
    const result = client.send(new GetRabbitClusterStatusCommand({brokerId: brokerId, instanceId: instanceId, formatter: formatter}));
    return result;
}

export async function getRabbitBrokerQueues(brokerId: string, instanceId: string | undefined): Promise<GetRabbitBrokerQueuesCommandOutput> {
    const client = await getClient();
    const result = client.send(new GetRabbitBrokerQueuesCommand({brokerId: brokerId, instanceId: instanceId}));
    return result;
}

export async function getRabbitVhosts(brokerId: string, instanceId: string | undefined): Promise<GetRabbitBrokerVhostsOutput> {
    const client = await getClient();
    const result = client.send(new GetRabbitBrokerVhostsCommand({brokerId: brokerId, instanceId: instanceId}));
    return result;
}

export async function getRabbitQueue(brokerId: string, instanceId: string, vhost: string, queue: string): Promise<DescribeRabbitQueueCommandOutput> {
    const client = await getClient();
    const result = client.send(new DescribeRabbitQueueCommand({brokerId: brokerId, instanceId: instanceId, vhost: vhost, queueName: queue}));
    return result;
}

export async function getQuorumStatus(brokerId: string, instanceId: string, vhost: string, queue: string): Promise<GetQuorumStatusCommandOutput> {
    const client = await getClient();
    const result = client.send(new GetQuorumStatusCommand({brokerId: brokerId, instanceId: instanceId, vhost: vhost, queueName: queue}));
    return result;
}

export async function getRabbitUnresponsiveBrokerQueues(brokerId: string, instanceId: string): Promise<GetRabbitBrokerUnresponsiveQueuesCommandOutput> {
    const client = await getClient();
    const result = client.send(new GetRabbitBrokerUnresponsiveQueuesCommand({brokerId: brokerId, instanceId: instanceId}));
    return result;
}

export function getRabbitBrokerConfigOverride(brokerId: string): Promise<any> {
     // TODO not implemented yet
     return Promise.reject()
}

export async function updateRabbitBrokerConfigOverride(brokerId: string, data: {
    configName: string;
    configValue: string;
}): Promise<any> {
    // TODO not implemented yet
    return Promise.reject()
}

export async function getRabbitBrokerPolicies(brokerId: string): Promise<GetRabbitBrokerPoliciesCommandOutput> {
    const client = await getClient();
    const result = client.send(new GetRabbitBrokerPoliciesCommand({brokerId: brokerId}));
    return result;
}

export async function updateBrokerState(brokerId: string, newState: string, ticketId: string): Promise<UpdateBrokerStateCommandOutput> {
    const client = await getClient();
    const result = client.send(new UpdateBrokerStateCommand({brokerId: brokerId, newState: newState}));
    return result;
}

export async function addRabbitBrokerTag(brokerId: string, tag: string): Promise<AddBrokerTagCommandOutput> {
    const client = await getClient();
    const result = client.send(new AddBrokerTagCommand({brokerId: brokerId, tagName: tag}));
    return result;
}

export async function getRabbitBrokerTags(brokerId: string): Promise<GetBrokerTagsCommandOutput> {
    const client = await getClient();
    const result = client.send(new GetBrokerTagsCommand({brokerId: brokerId}));
    return result;
}

export async function deleteRabbitBrokerTag(brokerId: string, tag: string): Promise<RemoveBrokerTagCommandOutput> {
    const client = await getClient();
    const result = client.send(new RemoveBrokerTagCommand({brokerId: brokerId, tagName: tag}));
    return result;
}

export async function getRabbitBrokerTimeline(brokerId: string, startTime: Date, endTime: Date, request: GetBrokerTimelineEventCollectionRequest): Promise<GetRabbitBrokerTimelineCommandOutput> {
    const client = await getClient();
    const result = client.send(new GetRabbitBrokerTimelineCommand({brokerId: brokerId, startTime: startTime, endTime:endTime, request: request}));
    return result;
}

export async function getBrokerEngineVersions(brokerEngineType: BrokerEngineType): Promise<GetBrokerEngineVersionsCommandOutput> {
    const client = await getClient();
    const result = client.send(new GetBrokerEngineVersionsCommand({brokerEngineType: brokerEngineType}));
    return result;
}